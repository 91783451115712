<template>
  <div>
    <ValidationObserver ref="observer">
      <b-form @submit.prevent="onSubmit">
        <b-card no-body class="mb-1">
          <b-card-header class="px-1 pt-1 pb-0">
              <b-button
                size="sm"
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                style="padding: .5rem 10px!important"
                @click="deleteInbox(inboxDto.id)"
              >
              حذف البريد
                <unicon name="trash-alt" width="18"></unicon>
              </b-button>
          </b-card-header>
          <b-card-body>
            <b-row>
              <b-col cols="12" lg="6">
                
                <BTextInputWithValidation
                  label="الاسم"
                  name="name"
                  :value="inboxDto.name"
                  disabled
                />
                <BTextInputWithValidation
                  label="العنوان"
                  name="subject"
                  :value="inboxDto.title"
                  disabled
                />
                
              </b-col>
              <b-col cols="12" lg="6">
                <BTextInputWithValidation
                  label="تاريح الإرسال"
                  name="date"
                  :value="inboxDto.sendDate | formatArDate"
                  disabled
                />
                <BTextInputWithValidation
                  label="الايميل"
                  name="email"
                 :value="inboxDto.email"
                  disabled
                />
                
              </b-col>
            </b-row>
            <b-form-group label="الرسالة">
                  <b-form-textarea
                    disabled
                    rows="5"
                    v-model="inboxDto.description"
                  ></b-form-textarea>
                </b-form-group>
          </b-card-body>
        </b-card>

        <b-card no-body class="mb-1">
          <b-card-header class="px-1 pt-1 pb-0">
            <h4>الرد على البريد</h4>
            <span v-if="!!inboxDto.responseDate">تاريخ الرد: {{
               inboxDto.responseDate | formatArDate
            }}</span>
          </b-card-header>
          <b-card-body class="p-1 d-flex align-items-center">
            <validation-provider class="w-100" name="نص الرد" rules="required">
              <b-form-group class="mb-0" slot-scope="{ errors }" label="نص الرد:">
                <div class="editor-container" v-if="!inboxDto.responseDate">
                  <quill-editor
                    class="editor"
                    ref="myTextEditor"
                    :value="inboxDto.response"
                    :options="editorOption"
                    @change="onEditorChange"
                  />
                </div>
                <p class="lead" v-else v-html="inboxDto.response"></p>
                <small class="text-danger mt-5" v-show="!inboxDto.response"
                  >يرجى كتابة رد للرسالة</small
                >
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-card-body>
          <b-card-footer class="pt-0 border-0 pb-1 px-1">
            <b-button v-if="!inboxDto.responseDate" variant="primary" type="submit">إرسال الرد</b-button>
          </b-card-footer>
        </b-card>
      </b-form>
    </ValidationObserver>
  </div>
</template>
<script>
import { localize, ValidationObserver, ValidationProvider } from "vee-validate";
import BTextInputWithValidation from "@core/components/inputs/BTextInputWithValidation";
import { mapActions, mapGetters } from "vuex";
import { quillEditor } from "vue-quill-editor";
import hljs from "highlight.js";
import { required } from "@validations";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    quillEditor,
    BTextInputWithValidation,
  },
  props: {
    id: String,
  },
  data: () => ({
    required,
    editorOption: {
      modules: {
        toolbar: [
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "code-block"],
          [{ header: 1 }, { header: 2 }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ direction: "rtl" }],
          [{ size: ["small", false, "large", "huge"] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ font: [] }],
          [{ color: [] }, { background: [] }],
          [{ align: [] }],
          ["clean"],
          ["link", "image", "video"],
        ],
        syntax: {
          highlight: (text) => hljs.highlightAuto(text).value,
        },
      },
      placeholder: "اكتب هنا",
    },
  }),
  computed: {
    ...mapGetters(["inboxDto"]),
  },
  created() {
    this.getInboxById(this.id);
    localize("ar");
  },
  methods: {
    ...mapActions(["getInboxById", "setContactReply", "deleteInbox"]),
    onSubmit() {
      this.$refs.observer.validate().then((success) => {
        if (success && !!this.inboxDto.response) {
          this.setContactReply(this.inboxDto);
        }
      });
    },
    onEditorChange(value) {
      this.inboxDto.response = value.html;
    },
  },
};
</script>
